import React from "react";
import SbEditable from "storyblok-react";
import StoryblokClient from "storyblok-js-client";
// import crypto from 'crypto'
import isNode from "is-node";
import Image from "src/components/UI/Image/Image";
import * as styles from "./editor.module.scss";
import Components from "../../storyblok/Components";
import Artwork from "src/components/Artwork/Artwork";
const resolve_relations = ["product.product"];

const getQueryParam = (param: string): string => {
  if (!isNode) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param) as string;
  } else {
    return "";
  }
};

const getParam = function (val: any) {
  let result = "";
  let tmp = [];

  window.location.search
    .substr(1)
    .split("&")
    .forEach(function (item) {
      tmp = item.split("=");
      if (tmp[0] === val) {
        result = decodeURIComponent(tmp[1]);
      }
    });

  return result;
};

// loadStory is triggered on init and on input change in storyblok
// if the story uses references in storyblok, they will be fetched on every loadStory
//
// if the story uses some kind of post data where we fetch all of a type, that data will be
// fetched on init with fetchPostsBySlug and added to extraContent

class StoryblokEntry extends React.Component {
  storyblok: any;
  editMode = true;

  constructor(props: any) {
    super(props);

    // this.validateUser()

    this.state = { story: null, globalData: null, extraContent: null };
  }

  loadStoryblokBridge = (cb: any) => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    if (this.editMode) {
      script.src = `//app.storyblok.com/f/storyblok-latest.js?t=${getQueryParam(
        "_skey"
      )}`;
      script.onload = cb;
      document.getElementsByTagName("head")[0].appendChild(script);
    }
  };

  componentDidMount() {
    if (this.editMode) {
      this.storyblok = new StoryblokClient({
        accessToken: getQueryParam("_skey"),
        cache: {
          clear: "auto",
          type: "memory",
        },
      });
      this.loadStoryblokBridge(() => {
        this.initStoryblokEvents();
      });
    }
  }

  async fetchPostsBySlug(slug: string, parser?: any, filter_query?: any) {
    let posts: any = [];

    await this.storyblok
      .get("cdn/stories", {
        by_slugs: slug,
        sort_by: "published_at",
        per_page: 100,
        filter_query: filter_query ? filter_query : {},
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          posts = parser
            ? response.data.stories.map((story: any) => parser(story))
            : response.data.stories;
        }
      });

    return posts;
  }

  async fetchPostsByComponent(component: string) {
    let posts: any = [];

    await this.storyblok
      .get("cdn/stories", {
        by_slugs: "*",
        sort_by: "published_at",
        per_page: 100,
        filter_query: {
          component: {
            in: component,
          },
        },
      })
      .then((response: any) => {
        if (response && response.data && response.data.stories) {
          posts = response.data.stories;
        }
      });

    return posts;
  }

  fetchReferences(story: any, key: string, parser?: any) {
    if (
      story &&
      story.content &&
      story.content[key] &&
      story.content[key].length > 0
    ) {
      this.storyblok
        .get("cdn/stories", {
          by_uuids: story.content[key].join(","),
        })
        .then((response: any) => {
          if (response && response.data && response.data.stories) {
            story.content[key] = parser
              ? response.data.stories.map((story: any) => parser(story))
              : response.data.stories;
          }
          this.setState({ story: story });
        });
    }
  }

  async fetchSiblings(story: any) {
    let starts_with = story.full_slug;
    let links: any = [];
    if (!story.is_startpage) {
      starts_with = story.full_slug.replace(story.slug, "");
    }
    await this.storyblok
      .get("cdn/links/", {
        starts_with: starts_with,
      })
      .then((response: any) => {
        if (response && response.data && response.data.links) {
          links = Object.values(response.data.links).map((link: any) => ({
            slug: link.slug,
            name: link.name,
          }));
        }
      })
      .catch((error: any) => {
        console.log(error);
      });

    return links;
  }

  loadStory(init?: boolean) {
    this.storyblok
      .get(`cdn/stories/${getParam("path")}`, {
        version: "draft",
        resolve_relations: resolve_relations.join(","),
      })
      .then(async (response: any) => {
        const { data } = response;
        if (init) {
          const component = data?.story?.content?.component;

          if (component === "page_home" || component === "footer") {
            this.loadSiteConfig();
          }
        }
        if (data && data.story) {
          this.setState({ story: data.story });
        }
      });
  }

  async loadSiteConfig() {
    const config = await this.fetchPostsByComponent("site");
    const site =
      config && config.length > 0 && config[0] && config[0] ? config[0] : {};

    if (site) {
      this.setState({ globalData: { site } });
    }
  }

  initStoryblokEvents() {
    this.loadStory(true);

    const sb = window.storyblok;

    sb.on(["change", "published"], (payload) => {
      this.loadStory();
    });

    sb.on("input", (payload: any) => {
      if (this.state.story && payload.story.id === this.state.story.id) {
        payload.story.content = sb.addComments(
          payload.story.content,
          payload.story.id
        );
        sb.resolveRelations(payload.story, resolve_relations, () => {
          if (payload && payload.story) {
            this.setState({ story: payload.story });
          }
        });
      }
    });

    sb.pingEditor(() => {
      if (sb.inEditor) {
        sb.enterEditmode();
      }
    });
  }

  render() {
    const { story, globalData, extraContent } = this.state;

    if (story == null) {
      return <div />;
    }

    let content = story.content;
    content = {
      ...content,
      ...extraContent,
    };

    const { background, background_mobile } =
      globalData && globalData.site && globalData.site.content
        ? globalData.site.content
        : {};

    return (
      <SbEditable content={content}>
        <div className={styles.editor}>
          <Artwork
            background={
              background && background.filename ? background.filename : null
            }
            backgroundMobile={
              background_mobile && background_mobile.filename
                ? background_mobile.filename
                : null
            }
          />
          <main className={styles.page}>
            {React.createElement(Components(story.content.component), {
              storyID: story.uuid,
              tags: story.tag_list,
              name: story.name,
              slug: story.slug,
              isStartPage: story.is_startpage,
              full_slug: story.full_slug,
              date: story.published_at,
              blok: content,
              preview: true,
            })}
          </main>
        </div>
      </SbEditable>
    );
  }
}

export default StoryblokEntry;
