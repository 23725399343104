import React from "react";
import classnames from "classnames";
import Image from "src/components/UI/Image/Image";
import * as styles from "./Artwork.module.scss";
import { Props } from "./types";

const Artwork = ({ background, backgroundMobile }: Props): JSX.Element => {
  return (
    <React.Fragment>
      {background && (
        <Image
          className={classnames(styles.background, "hideMobile")}
          src={background}
          alt={""}
          size="100vw"
        />
      )}
      {backgroundMobile && (
        <Image
          className={classnames(styles.background, "hideDesktop")}
          src={backgroundMobile}
          alt={""}
          size="100vw"
        />
      )}
    </React.Fragment>
  );
};

export default Artwork;
